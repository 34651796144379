import styled from 'styled-components';
import { FaCar, FaCarSide, FaCarAlt, FaQuestion } from 'react-icons/fa';
import Tooltip from '../../../components/datagridComp/Tooltip';
import { AuthConfig } from '../../../Config/AuthContext';

const VehicleButton = styled.button<{ color: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ color }) => color};
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    
   
    svg {
        margin-right: 5px;
    }
`;

const VehicleCell = ({ idgroup, rule, vehicle_count, onClick }: any) => {
    const { user } = AuthConfig();
    let content = null;
    let icon = null;
    let color = '';
    let title = '';

    let userRule = user?.nivel || 0;

    if (rule === 3 && vehicle_count === 0) {
        content = userRule === 2 ? 'Cliente' : 'Todos';
        icon = <FaCarSide />;
        color = '#858585'; // Cinza claro
        title = userRule === 2 ? 'Veículos do Cliente' : 'Todos os Veículos';
    } else if (rule === 3 && vehicle_count > 0 && idgroup === 2) {
        content = `(${vehicle_count}) `;
        icon = <FaCarSide />;
        color = '#2b2b2b'; // Azul claro
        title = `${vehicle_count} Veículos Vinculado ao Usuário`;
    } else if (rule === 3 && vehicle_count > 0 && idgroup === 1) {
        content = ` + ${vehicle_count} `;
        icon = <FaCarAlt />;
        color = '#2b2b2b'; // Azul claro
        title = `Veículos Do Cliente + ${vehicle_count} de Terceiros`;
    } else if (rule === 2 && vehicle_count === 0) {
        content = 'Todos';
        icon = <FaCarAlt />;
        color = '#00167a'; // Verde claro
        title = 'Todos os Veículos Admin';
    } else if (rule === 2 && vehicle_count > 0) {
        content = `${vehicle_count}`;
        icon = <FaCar />;
        color = '#ffa07a'; // Laranja claro
        title = 'Veículos ativos do Admin';
    } else {
        content = 'N/A';
        icon = <FaQuestion />;
        color = '#ffcccb'; // Vermelho claro
    }

    return (
        <Tooltip text={title}>
            <VehicleButton color={color} onClick={onClick}>
                {icon}
                {content}
            </VehicleButton>
        </Tooltip>
    );
};

export default VehicleCell;
